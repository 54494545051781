<template>
  <div>
    <div class="ps_box-top-css">
      <div class="ps_box-top-css-div">{{titleList[current]}}</div>
      <div class="ps_box-top-css-after" @click="currentClick('-')"></div>
      <div class="ps_box-top-css-before" @click="currentClick('+')"></div>
    </div>
    <div class="ps_box-content-css ps_height452">
      <div class="screen_box_css">
        <div class="screen_box_item_css" v-for="(item, index) in showList" :key="index" :class="{ select_box_css: showValue == index }" @click="selectionRange(index, item)">
          {{ item }}
        </div>
        <el-date-picker @change="pickerChange()"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd" v-model="timeValue" type="daterange" align="right" unlink-panels class="pickerTime" ref="datePicker" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div v-if="showE" class="teacher_box_css">
        <div v-for="(item, index) in list" :key="index">
          <div class="block_css"></div>
          <div style="width: 100%">
            {{ item.className }}
          </div>
          <div style="width: 100%">
            {{ item.userName }}
          </div>
          <div style="flex-shrink: 0">
            {{ Number(item.Score) }}
          </div>
        </div>
      </div>
      <div v-else class="NoRankingYet">
        暂 无 排 名
      </div>
      <div class="ps_content-border-css">
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { StudentScoreRankingReport } from "@/api/index.js";

export default {
  data() {
    return {
      showValue: 0,
      showList: ["本学期", "本月", "本周", "自定义"],
      titleList:['全校学生榜','一年级学生榜','二年级学生榜','三年级学生榜','四年级学生榜','五年级学生榜','六年级学生榜'],
      current: 0,
      showE: true,
      list: [],
      arrList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      timeValue: "",
      startDate: '',
      endDate: '',
      time: ''
    };
  },
  created(){
    this.time = localStorage.getItem('newTime') || moment().format('YYYY-MM-DD')
    this.getStudentScoreRankingReport()
  },
  methods: {
    getStudentScoreRankingReport(){
      this.showE = true
      StudentScoreRankingReport({
        top:10,
        Grade:this.current,
        startDate: this.startDate,
        endDate: this.endDate
      }).then(res =>{
        this.list = res.data
        if(!this.startDate){
          this.arrList = res.data
        }
        if(res.data.length == 0){
          this.showE = false
        } else {
          this.showE = true
        }
      })
    },
    selectionRange(index, item) {
      if (item == "自定义") {
        this.$refs.datePicker.focus();
      } else {
        this.timeValue = ''
        this.showValue = index;
        if(index == 0){
          this.startDate = ''
          this.endDate = ''
          this.list = this.arrList
        } else if(index == 1){
          this.startDate = moment().format('YYYY-MM') + '-01'
          this.endDate = moment().format('YYYY-MM-DD')
        } else if(index == 2){
          let date = moment();
          let dow = date.day();
          this.startDate = date.subtract(dow-1, 'days').format('YYYY-MM-DD')
          this.endDate = moment().format('YYYY-MM-DD')
        }
        this.getStudentScoreRankingReport()
      }
    },
    // 选择日期
    pickerChange(){
      this.showValue = 3
      this.startDate = this.timeValue[0]
      this.endDate = this.timeValue[1]
      this.getStudentScoreRankingReport()
    },
    // 切换年级
    currentClick(e) {
      if (e == "-") {
        if (this.current <= 0) {
          this.current = 6;
        } else {
          this.current--;
        }
      } else if (e == "+") {
        if (this.current >= 6) {
          this.current = 0;
        } else {
          this.current++;
        }
      }
      this.getStudentScoreRankingReport()
    },
  },
};
</script>

<style scoped>
.block_css {
  width: 15px;
  height: 15px;
  border-radius: 1000px;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  font-size: 18px;
  background-color: #52bee948;
  margin-right: 14px;
  flex-shrink: 0;
}
.teacher_box_css {
  padding: 10px 25px;
  box-sizing: border-box;
  font-size: 17px;
}
.teacher_box_css > div {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.teacher_box_css > div:nth-child(1) > .block_css {
  background-color: #bf3a50 !important;
}
.teacher_box_css > div:nth-child(2) > .block_css {
  background-color: #be693b !important;
}
.teacher_box_css > div:nth-child(3) > .block_css {
  background-color: #427bf5 !important;
}
</style>
