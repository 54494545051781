<template>
  <div class="ps_pages_Css">
    <img class="ps_top_bg_css" src="@/assets/principalScreen/9529csccccc.png" alt="" />
    <div>
      <!-- 中间大图 -->
      <masterMap />

      <!-- 全校班级排名 -->
      <classRanking class="ps_box_css ps_classRanking" />

      <!-- 全校德智体美劳 -->
      <evaluate class="ps_box_css ps_evaluate" />

      <!-- 教师、家长点赞 -->
      <teacher class="ps_box_css ps_teacher" />

      <!-- 学生获赞 -->
      <parent class="ps_box_css ps_patriarch" />
    </div>
  </div>
</template>
<script>
import masterMap from "./masterMap.vue";
import classRanking from "./classRanking.vue";
import evaluate from "./evaluate.vue";
import teacher from "./teacher.vue";
import parent from "./parent.vue";
export default {
  components: {
    classRanking,
    evaluate,
    teacher,
    masterMap,
    parent,
  },
  data() {
    return {
      
    };
  },
  methods: {
  },
};
</script>
<style>
.el-picker-panel,
.el-dropdown-menu {
  background-color: #082f6ed2;
  border: 1px solid #053581d2;
  color: #ffffffd5;
}
.el-picker-panel__sidebar {
  background-color: transparent;
  border-right: 1px solid #3862a7;
  color: #ffffffd5;
}
.el-picker-panel__icon-btn,
.el-dropdown-menu__item {
  color: #ffffffd5;
}
.el-date-table td.in-range div,
.el-date-table td.in-range div:hover {
  background-color: #315ea5d2;
}
.el-popper[x-placement^="top"] .popper__arrow {
  border-top-color: #082f6ed2;
}
.el-popper[x-placement^="top"] .popper__arrow::after {
  border-top-color: #082f6ed2;
}
.el-popper[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: #082f6ed2;
}
.el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #082f6ed2;
}
.el-date-range-picker__content.is-left {
  border-right: 1px solid #3862a7;
}
.el-date-table th,
.el-picker-panel__shortcut {
  color: inherit;
}
.el-date-table th {
  border-bottom: 1px solid #3862a7;
}
.screen_box_css {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 15px 5px 0;
  position: relative;
}
.screen_box_item_css {
  margin-left: 10px;
  border-radius: 4px;
  background-color: #082a60d3;
  color: #d1d1d1;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  cursor: pointer;
}
.select_box_css {
  background-color: #1250b3 !important;
  color: #fff !important;
}
.pickerTime {
  opacity: 0;
  position: absolute;
  right: 64px;
  top: 15px;
  width: 0 !important;
  height: 0;
}
.pickerTimeA {
  opacity: 0;
  position: absolute;
  right: 50%;
  top: 15px;
  width: 0 !important;
  height: 0 !important;
}
.pickerTimeA > input {
  height: 0 !important;
  padding: 0 !important;
}
.el-date-table td.disabled div {
  background-color: #232444;
}
.el-date-picker__header-label {
  color: #fff;
}
</style>
<style>
.ps_pages_Css {
  /* background-image: url("../../assets/principalScreen/5e743a83226171584675459667.jpg");
  background-size: cover; */
  background-color: #000127;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.ps_top_bg_css {
  position: fixed;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
  right: 0;
  top: 0;
  width: 120%;
}
.ps_left_css {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50px;
}
.ps_right_css {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50px;
}
.ps_box_css {
  position: absolute;
  width: 470px;
  color: #e7e7e7d5;
  overflow: hidden;
}
.ps_dailyAttendance {
  width: 1335px !important;
  right: 40px;
  top: 560px;
}
.ps_dailyAttendance > .ps_box-top-css {
  background-image: linear-gradient(to right, transparent, #68590118, #68590156, #685801b0, #68590156, #68590118, transparent) !important;
}

.ps_classRanking {
  left: 40px;
  top: 510px;
}
.ps_evaluate {
  left: 40px;
  top: 100px;
}
.ps_student {
  right: 40px;
  top: 100px;
}
.ps_teacher {
  right: 40px;
  top: 100px;
}
.ps_patriarch {
  right: 40px;
  top: 410px;
}
.ps_leaveStatistics {
  width: 828px !important;
  left: 546px;
  top: 210px;
}
.ps_leaveNumber {
  left: 40px;
  top: 100px;
}
.ps_leaveNumberA {
  left: 40px;
  top: 512px;
}
.ps_leaveCompare {
  right: 40px;
  top: 100px;
}
.ps_leaveBox {
  position: absolute;
  top: 105px;
  left: 50%;
  z-index: 999;
  transform: translateX(-50%);
}
.ps_box-top-css {
  position: relative;
  width: 100%;
  height: 44px !important;
  padding: 6px 0 6px 0;
  box-sizing: border-box;
  background-image: linear-gradient(to right, transparent, #020d2562, #020d25b0, #02132eb0, #020d25b0, #020d2562, transparent);
}
.ps_box-top-css-div {
  text-align: center;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  font-weight: bold;
  background-image: linear-gradient(to right, transparent, #000e25, #041d46, #082863, #041d46, #000e25, transparent);
}
.ps_box-top-css-div > span,
.ps_span_css {
  cursor: pointer;
  color: #e7e7e7d5;
  font-size: 16px;
}
.ps_box-top-css-div > span:hover,
.ps_span_css:hover {
  color: #a1a4ff;
}
.ps_box-top-css-after {
  position: absolute;
  left: 0;
  top: 50%;
  background-image: url("../../assets/principalScreen/9529c07e20fafa0c12af103aa56144f.png");
  width: 32px;
  height: 38px;
  background-size: 32px 38px;
  transform: translateY(-50%);
  animation: twinkling 2.1s linear infinite;
}
.ps_box-top-css-before {
  position: absolute;
  right: 0;
  top: 50%;
  background-image: url("../../assets/principalScreen/9529c07e20fafa0c12af103aa56144f.png");
  width: 32px;
  height: 38px;
  background-size: 32px 38px;
  transform: translateY(-50%) rotate(180deg);
  animation: twinkling 2.1s linear infinite;
}
.ps_dailyAttendance > .ps_box-top-css > .ps_box-top-css-div {
  background-image: linear-gradient(to right, transparent, #eb99000e, #eb990069, #eb98009c, #eb990069, #eb99000e, transparent) !important;
}
@keyframes twinkling {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.ps_box-content-css {
  background-color: #1f11412f;
  /* background-color: red; */
  height: 200px;
  position: relative;
  overflow: hidden !important;
  clip-path: polygon(0 0, 100% 0, 100% 180px, 450px 100%, 20px 100%, 0 180px);
}
.ps_height350 {
  height: 350px !important;
  clip-path: polygon(0 0, 100% 0, 100% 330px, 450px 100%, 20px 100%, 0 330px) !important;
}
.ps_height340 {
  height: 760px !important;
  clip-path: polygon(0 0, 100% 0, 100% 740px, 450px 100%, 20px 100%, 0 740px) !important;
}
.ps_height250 {
  height: 250px !important;
  clip-path: polygon(0 0, 100% 0, 100% 230px, 450px 100%, 20px 100%, 0 230px) !important;
}
.ps_height452 {
  height: 452px !important;
  clip-path: polygon(0 0, 100% 0, 100% 432px, 450px 100%, 20px 100%, 0 432px) !important;
}
.ps_dailyAttendance_content {
  height: 300px !important;
  clip-path: polygon(0 0, 100% 0, 100% 280px, 1315px 100%, 20px 100%, 0 280px) !important;
}
.ps_leaveStatistics_content {
  height: 280px !important;
  clip-path: polygon(0 0, 100% 0, 100% 260px, 808px 100%, 20px 100%, 0 260px) !important;
}
.ps_leaveCompare_content {
  height: 390px !important;
  clip-path: polygon(0 0, 100% 0, 100% 370px, 450px 100%, 20px 100%, 0 370px) !important;
}
.ps_content-border-css {
  --box-show-color: #4459b8;
  position: absolute;
  bottom: 0;
  left: 0px;
  right: 0px;
  height: 2px;
  background-color: var(--box-show-color);
}
.ps_content-border-css::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  height: 2px;
  width: 100px;
  transform: translateX(-40px) rotate(45deg);
  background-color: var(--box-show-color);
}
.ps_content-border-css::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 10px;
  height: 2px;
  width: 100px;
  transform: translateX(40px) rotate(-45deg);
  background-color: var(--box-show-color);
}
.ps_content-border-css > div {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
}
.ps_content-border-css > div::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 2px;
  height: 100px;
  background-image: linear-gradient(0deg, var(--box-show-color), transparent);
}
.ps_content-border-css > div::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 2px;
  height: 100px;
  background-image: linear-gradient(0deg, var(--box-show-color), transparent);
}

.NoRankingYet {
  text-align: center;
  line-height: 280px;
}
</style>
