<template>
  <div>
    <!-- <img class="onbdhif65214788633255" src="@/assets/principalScreen/442578521668848trhf.png" alt="" /> -->
    <img class="content_bg_css" src="@/assets/principalScreen/cscsktjhijdnjas56.png" alt="" />
    <img class="box_content_bg_css" src="@/assets/principalScreen/cscfppkkkm3.png" alt="" />
    <!-- <img class="c442578521668848trhf" src="@/assets/principalScreen/onbdhif65214788633255.png" alt="" /> -->
    <div class="block_box_css">
      <div v-for="(item, index) in listNumber" :key="index">
        <div>{{ item.number }}</div>
        <div>{{ item.title }}</div>
        <img src="@/assets/principalScreen/vbnhnhdrrejkgkhjs451.png" alt="" />
      </div>
    </div>
    <div id="appraise" class="appraise-appraise-css"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
let myChart = null;
import { SchoolReportGrowthTrends2, SchoolUserData } from "@/api/index.js";
export default {
  data() {
    return {
      valArr: [0, 0, 0, 0, 0],
      listNumber: [
        {
          title: "总人数",
          number: 4250,
        },
        {
          title: "管理人员",
          number: 84,
        },
        {
          title: "教师数量",
          number: 860,
        },
        {
          title: "学生数量",
          number: 3650,
        },
      ],
    };
  },
  created() {
    SchoolReportGrowthTrends2({
      startDate: "",
      endDate: "",
    }).then((res) => {
      let listArr = [
        { ttID: "1", Score: 0 },
        { ttID: "2", Score: 0 },
        { ttID: "3", Score: 0 },
        { ttID: "4", Score: 0 },
        { ttID: "5", Score: 0 },
      ];
      for (let i = 0; i < listArr.length; i++) {
        for (let j = 0; j < res.data.length; j++) {
          if (listArr[i].ttID == res.data[j].ttID) {
            listArr[i].Score = Number(res.data[j].Score);
            break;
          }
        }
      }
      this.valArr = listArr.map(function(obj) {
        return obj.Score;
      });
      this.appraise();
    });
    
    SchoolUserData().then((res) => {
      this.listNumber[0].number = res.data.alluser;
      this.listNumber[1].number = res.data.manager;
      this.listNumber[2].number = res.data.teacher;
      this.listNumber[3].number = res.data.student;
    });
  },
  methods: {
    async appraise() {
      if (myChart) {
        myChart.dispose();
      }

      let areaData = [
        {
          name: "德",
          max: 1000,
          value: this.valArr[0],
        },
        {
          name: "智",
          max: 1000,
          value: this.valArr[1],
        },
        {
          name: "体",
          max: 1000,
          value: this.valArr[2],
        },
        {
          name: "美",
          max: 1000,
          value: this.valArr[3],
        },
        {
          name: "劳",
          max: 1000,
          value: this.valArr[4],
        },
      ];
      let data = [];
      areaData.forEach((item) => {
        data.push(item.value);
      });
      let maxNum = Math.max(...data); //获取数组中最大的值
      // 向上取整十，整百，整千，整万的方法
      function ceilNumber(number) {
        let bite = 0;
        if (number < 10) {
          return 10;
        }
        while (number >= 10) {
          number /= 10;
          bite += 1;
        }
        return Math.ceil(number) * Math.pow(10, bite);
      }
      let allMax = ceilNumber(maxNum); //将最大的值进行向上取整
      areaData.forEach((item) => {
        item.max = allMax;
      });
      let data1 = [0, 0, 0, 0, 0, 0];
      let data2 = [0, 0, 0, 0, 0, 0];
      let data3 = [0, 0, 0, 0, 0, 0];
      let data4 = [0, 0, 0, 0, 0, 0];
      let data5 = [0, 0, 0, 0, 0, 0];
      data1[0] = data[0];
      data2[1] = data[1];
      data3[2] = data[2];
      data4[3] = data[3];
      data5[4] = data[4];

      myChart = null;
      this.$forceUpdate();
      await new Promise((res) => setTimeout(res, 50));
      myChart = echarts.init(document.getElementById("appraise"));
      // 绘制图表
      let colorArr = ["#5b7ad7", "#91cc75", "#fac858", "#f76b6b", "#73c0de"];
      myChart.setOption({
        color: colorArr,
        legend: {
          trigger: "item",
          x: 'center',
          bottom: '0%',
          textStyle: {
            color: "#fff",
            fontSize:14
          },
        },
        tooltip: {
          trigger: "item",
        },
        radar: {
          zlevel: 11,
          shape: "circle",
          scale: true,
          indicator: areaData,
          center: ["50%", "47%"],
          radius: "56%",
          nameGap: "0",
          startAngle: 90,
          name: {
            show: false,
            color: "red",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(32,126,255, .5)",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgba(32,126,255, .5)",
            },
          },
          splitArea: {
            areaStyle: {
              color: "rgba(32,126,255, 0.1)",
            },
          },
        },
        polar: {
          radius: "56%",
          center: ["50%", "47%"],
        },
        angleAxis: {
          zlevel: 0,
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        radiusAxis: {
          zlevel: 0,
          min: 0,
          max: allMax,
          axisLabel: {
            textStyle: {
              color: "rgba(29,208,240,.5)",
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            type: "radar",
            symbolSize: 12,
            symbol: "circle",
            tooltip: {
              trigger: "none",
            },
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  formatter: function(params) {
                    if (params.value !== 0) {
                      return params.value;
                    } else {
                      return "";
                    }
                  },
                  color: "#ffffff",
                  fontSize: 12,
                  distance: 0,
                },
              },
            },
            areaStyle: {
              normal: {
                color: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(32,126,255, 0.5)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(32,126,255, 0.1)", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            data: [
              {
                value: data,
              },
              {
                value: data1,
                itemStyle: {
                  color: colorArr[0],
                },
                lineStyle: {
                  color: "transparent", //设置折线的颜色
                },
              },
              {
                value: data2,
                itemStyle: {
                  color: colorArr[1],
                },
                lineStyle: {
                  color: "transparent", //设置折线的颜色
                },
              },
              {
                value: data3,
                itemStyle: {
                  color: colorArr[2],
                },
                lineStyle: {
                  color: "transparent", //设置折线的颜色
                },
              },
              {
                value: data4,
                itemStyle: {
                  color: colorArr[3],
                },
                lineStyle: {
                  color: "transparent", //设置折线的颜色
                },
              },
              {
                value: data5,
                itemStyle: {
                  color: colorArr[4],
                },
                lineStyle: {
                  color: "transparent", //设置折线的颜色
                },
              },
              {
                value: [0, 0, 0, 0, 0, 0],
                symbolSize: 13,
                itemStyle: {
                  color: "rgba(40, 97, 132)",
                },
              },
            ],
            zlevel: 20,
            z: 4,
          },
          {
            type: "pie",
            startAngle: 90,
            clockwise: false, //逆时针
            data: areaData.map((item) => {
              return {
                name: item.name,
                value: item.value,
              };
            }),
            radius: ["62%", "80%"],
            center: ["50%", "47%"],
            zlevel: -1,
            labelLine: {
              normal: {
                length: 20,
                length2: 85,
              },
            },
            label: {
              normal: {
                formatter: (params) => {
                  return "{icon|●}{name|" + params.name + "}\n{value|" + params.value + "}";
                },
                rich: {
                  icon: {
                    fontSize: 22,
                    color: "inherit",
                  },
                  name: {
                    fontSize: 18,
                    padding: [0, 0, 0, 8],
                    color: "inherit",
                    fontWeight: "bolder",
                  },
                  value: {
                    fontSize: 19,
                    fontWeight: "bolder",
                    padding: [4, 0, 0, 10],
                    color: "inherit",
                  },
                },
              },
            },
          },
        ],
      });
    },
  },
};
</script>

<style scoped>
.c442578521668848trhf {
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  top: 95px;
  height: 26px;
}
.content_bg_css {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  top: 320px;
  width: 1450px;
}
.box_content_bg_css {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  top: 202px;
  width: 780px;
}

.box_box_content_bg_css {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  top: 610px;
  width: 690px;
}
.xinzhang_bg_css {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  top: 110px;
  width: 760px;
}
.onbdhif65214788633255 {
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  bottom: 140px;
  width: 160px;
}
.block_box_css {
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  top: 104px;
  display: flex;
  align-items: center;
}
.block_box_css > div {
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 170px;
  height: 65px;
}
.block_box_css > div:nth-child(1) {
  color: #08c7f1;
}
.block_box_css > div:nth-child(2) {
  color: #ffd725;
}
.block_box_css > div:nth-child(3) {
  color: #ad55ff;
}
.block_box_css > div:nth-child(4) {
  color: #0dde79;
}
.block_box_css > div > div:nth-child(1) {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 1px;
}
.block_box_css > div > div:nth-child(2) {
  font-size: 15px;
}
.block_box_css > div > img {
  width: 170px;
  position: absolute;
  top: 0;
  left: 0;
}
.appraise-appraise-css {
  width: 760px;
  height: 455px;
  left: 50%;
  transform: translateX(-50%);
  top: 210px;
  position: absolute;
  z-index: 9;
}
</style>
