<template>
  <div>
    <div class="ps_box-top-css">
      <div class="ps_box-top-css-div">{{ listArr[current] ? listArr[current].Title : "全校" }}</div>
      <div class="ps_box-top-css-after" @click="currentClick('-')"></div>
      <div class="ps_box-top-css-before" @click="currentClick('+')"></div>
    </div>
    <div class="ps_box-content-css ps_height350">
      <div class="screen_box_css">
        <div class="screen_box_item_css" v-for="item in showList" :key="item.value" :class="{ select_box_css: showValue == item.value || showValueB == item.value }" @click="selectionRange(item.value)">
          {{ item.label }}
        </div>
        <el-date-picker
          @change="getGradeReportGrowthTrends()"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          v-model="timeValue"
          type="daterange"
          align="right"
          unlink-panels
          class="pickerTime"
          ref="datePicker"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          @blur="pickerBlur"
        >
        </el-date-picker>
      </div>
      <div id="evaluate" class="evaluate-evaluate-css"></div>
      <div class="ps_content-border-css">
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
let myChart = null;
import { GradeReportGrowthTrends } from "@/api/index.js";
export default {
  data() {
    return {
      showValue: 2,
      showValueB: 0,
      showList: [
        { value: 4, label: "年" },
        { value: 3, label: "季度" },
        { value: 2, label: "月" },
        { value: 1, label: "周" },
        { value: 5, label: "自定义" },
      ],
      current: 0,
      listArr: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      timeValue: null,
    };
  },
  created() {
    this.getGradeReportGrowthTrends();
  },
  methods: {
    // 获取全校德智体美劳
    getGradeReportGrowthTrends() {
      GradeReportGrowthTrends({
        iType: this.showValue,
        startDate: this.timeValue ? this.timeValue[0] : '',
        endDate: this.timeValue ? this.timeValue[1] : ''
      }).then((res) => {
        this.listArr = res.data;
        this.evaluate();
      });
    },
    // 切换年级
    currentClick(e) {
      if (this.listArr.length == 0) {
        return;
      }
      if (e == "-") {
        if (this.current <= 0) {
          this.current = this.listArr.length - 1;
        } else {
          this.current--;
        }
      } else if (e == "+") {
        if (this.current >= this.listArr.length - 1) {
          this.current = 0;
        } else {
          this.current++;
        }
      }
      this.evaluate();
    },
    // 切换日期
    selectionRange(value) {
      if (value == 5) {
        this.showValueB = 5;
        this.$refs.datePicker.focus();
      } else {
        this.showValue = value;
        this.timeValue = null
        this.showValueB = 0;
        this.getGradeReportGrowthTrends();
      }
    },
    // 日期选择失去焦点
    pickerBlur(){
      if(!this.timeValue){
        this.showValueB = 0;
      }
    },
    // 绘制图表
    async evaluate() {
      let ddt = this.listArr[this.current].Charts;
      let seriesList = [];
      ddt.Series.map((val) => {
        seriesList.push({
          name: val.seriesName,
          type: "line",
          data: val.seriesData,
        });
      });
      if (myChart) {
        myChart.dispose();
      }

      myChart = null;
      this.$forceUpdate();
      await new Promise((res) => setTimeout(res, 50));
      myChart = echarts.init(document.getElementById("evaluate"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ddt.legend,
          textStyle: {
            color: "#d4d4d4",
          },
        },
        grid: {
          top: 32,
          bottom: 28,
          left: 60,
          right: 40,
        },
        xAxis: {
          type: "category",
          axisLabel: {
            color: "#d4d4d4",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#082863",
            },
          },
          boundaryGap: false,
          data: ddt.xAxis,
        },
        yAxis: {
          type: "value",
          axisLine: { show: true, lineStyle: { color: "#082863" } },
          axisLabel: { color: "#d4d4d4" },
          nameTextStyle: {
            color: "#fff",
            align: "right",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#082863",
            },
          },
        },
        series: seriesList,
      });
    },
  },
};
</script>
<style scoped>
.evaluate-evaluate-css {
  width: 470px;
  height: 315px;
}
</style>
