<template>
  <div>
    <div class="ps_box-top-css">
      <div class="ps_box-top-css-div">{{ teacherValue == 1 ? teacherObj.titleList[teacherObj.current] : patriarchObj.titleList[patriarchObj.current] }}</div>
      <div class="ps_box-top-css-after" @click="currentClick('-')"></div>
      <div class="ps_box-top-css-before" @click="currentClick('+')"></div>
    </div>
    <div class="ps_box-content-css ps_height250">
      <template v-if="teacherValue == 1">
        <div class="screen_box_css">
          <el-radio-group v-model="teacherValue">
            <el-radio :label="1">教师</el-radio>
            <el-radio :label="2">家长</el-radio>
          </el-radio-group>
          <div class="screen_box_css_div">
            <div class="screen_box_item_css" v-for="(item, index) in teacherObj.showList" :key="index" :class="{ select_box_css: teacherObj.showValue == index }" @click="selectionRangeA(index, item)">
              {{ item }}
            </div>
          </div>
          <el-date-picker
            @change="pickerChangeA()"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            v-model="teacherObj.timeValue"
            type="daterange"
            align="right"
            unlink-panels
            class="pickerTime"
            ref="datePicker"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div v-if="teacherObj.showE" class="teacher_box_css">
          <div v-for="(item, index) in teacherObj.list" :key="index">
            <div class="block_css">{{ index + 1 }}</div>
            <div style="width: 100%">
              {{ item.userName }}
            </div>
            <div style="flex-shrink: 0">
              {{ Number(item.allCount) }}
            </div>
          </div>
        </div>
        <div v-else class="NoRankingYet lineHeight-css">
          暂 无 排 名
        </div>
      </template>
      <template v-else>
        <div class="screen_box_css">
          <el-radio-group v-model="teacherValue">
            <el-radio :label="1">教师</el-radio>
            <el-radio :label="2">家长</el-radio>
          </el-radio-group>
          <div class="screen_box_css_div">
            <div class="screen_box_item_css" v-for="(item, index) in patriarchObj.showList" :key="index" :class="{ select_box_css: patriarchObj.showValue == index }" @click="selectionRangeB(index, item)">
              {{ item }}
            </div>
          </div>
          <el-date-picker
            @change="pickerChangeB()"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            v-model="patriarchObj.timeValue"
            type="daterange"
            align="right"
            unlink-panels
            class="pickerTime"
            ref="datePicker"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div v-if="patriarchObj.showE" class="teacher_box_css">
          <div v-for="(item, index) in patriarchObj.list" :key="index">
            <div class="block_css">{{ index + 1 }}</div>
            <div style="width: 100%">
              {{ item.userName }}
            </div>
            <div style="flex-shrink: 0">
              {{ Number(item.allCount) }}
            </div>
          </div>
        </div>
        <div v-else class="NoRankingYet lineHeight-css">
          暂 无 排 名
        </div>
      </template>
      <div class="ps_content-border-css">
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { EvaluatorScoreRankingReport } from "@/api/index.js";
export default {
  data() {
    return {
      teacher: true,
      teacherValue: 1,
      teacherObj: {
        titleList: ["全校教师榜", "一年级教师榜", "二年级教师榜", "三年级教师榜", "四年级教师榜", "五年级教师榜", "六年级教师榜"],
        current: 0,
        list: [],
        showValue: 0,
        showList: ["本学期", "本月", "本周", "自定义"],
        showE: true,
        timeValue: "",
        startDate: "",
        endDate: "",
        arrList: [],
      },
      patriarchObj: {
        titleList: ["全校家长榜", "一年级家长榜", "二年级家长榜", "三年级家长榜", "四年级家长榜", "五年级家长榜", "六年级家长榜"],
        current: 0,
        list: [],
        showValue: 0,
        showList: ["本学期", "本月", "本周", "自定义"],
        showE: true,
        timeValue: "",
        startDate: "",
        endDate: "",
        arrList: [],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      time: "",
    };
  },
  created() {
    this.time = localStorage.getItem("newTime") || moment().format("YYYY-MM-DD");
    this.getEvaluatorScoreRankingReport3();
    this.getEvaluatorScoreRankingReport2();
  },
  methods: {
    // 教师
    getEvaluatorScoreRankingReport3() {
      this.teacherObj.showE = true;
      EvaluatorScoreRankingReport({
        top: 10,
        Grade: this.teacherObj.current,
        startDate: this.teacherObj.startDate,
        endDate: this.teacherObj.endDate,
        Evaltype: 3,
      }).then((res) => {
        this.teacherObj.list = res.data;
        if (!this.teacherObj.startDate) {
          this.teacherObj.arrList = res.data;
        }
        if (res.data.length == 0) {
          this.teacherObj.showE = false;
        } else {
          this.teacherObj.showE = true;
        }
      });
    },
    // 家长
    getEvaluatorScoreRankingReport2() {
      this.patriarchObj.showE = true;
      EvaluatorScoreRankingReport({
        top: 10,
        Grade: this.patriarchObj.current,
        startDate: this.patriarchObj.startDate,
        endDate: this.patriarchObj.endDate,
        Evaltype: 2,
      }).then((res) => {
        this.patriarchObj.list = res.data;
        if (!this.patriarchObj.startDate) {
          this.patriarchObj.arrList = res.data;
        }
        if (res.data.length == 0) {
          this.patriarchObj.showE = false;
        } else {
          this.patriarchObj.showE = true;
        }
      });
    },
    selectionRangeA(index, item) {
      if (item == "自定义") {
        this.$refs.datePicker.focus();
      } else {
        this.teacherObj.timeValue = "";
        this.teacherObj.showValue = index;
        if (index == 0) {
          this.teacherObj.startDate = "";
          this.teacherObj.endDate = "";
          this.teacherObj.list = this.teacherObj.arrList;
        } else if (index == 1) {
          this.teacherObj.startDate = moment().format("YYYY-MM") + "-01";
          this.teacherObj.endDate = moment().format("YYYY-MM-DD");
        } else if (index == 2) {
          let date = moment();
          let dow = date.day();
          this.teacherObj.startDate = date.subtract(dow - 1, "days").format("YYYY-MM-DD");
          this.teacherObj.endDate = moment().format("YYYY-MM-DD");
        }
        this.getEvaluatorScoreRankingReport3();
      }
    },
    selectionRangeB(index, item) {
      if (item == "自定义") {
        this.$refs.datePicker.focus();
      } else {
        this.patriarchObj.timeValue = "";
        this.patriarchObj.showValue = index;
        if (index == 0) {
          this.patriarchObj.startDate = "";
          this.patriarchObj.endDate = "";
          this.patriarchObj.list = this.patriarchObj.arrList;
        } else if (index == 1) {
          this.patriarchObj.startDate = moment().format("YYYY-MM") + "-01";
          this.patriarchObj.endDate = moment().format("YYYY-MM-DD");
        } else if (index == 2) {
          let date = moment();
          let dow = date.day();
          this.patriarchObj.startDate = date.subtract(dow - 1, "days").format("YYYY-MM-DD");
          this.patriarchObj.endDate = moment().format("YYYY-MM-DD");
        }
        this.getEvaluatorScoreRankingReport2();
      }
    },
    // 选择日期
    pickerChangeA() {
      this.teacherObj.showValue = 3;
      this.teacherObj.startDate = this.teacherObj.timeValue[0];
      this.teacherObj.endDate = this.teacherObj.timeValue[1];
      this.getEvaluatorScoreRankingReport3();
    },
    // 选择日期
    pickerChangeB() {
      this.patriarchObj.showValue = 3;
      this.patriarchObj.startDate = this.patriarchObj.timeValue[0];
      this.patriarchObj.endDate = this.patriarchObj.timeValue[1];
      this.getEvaluatorScoreRankingReport2();
    },
    // 切换年级
    currentClick(e) {
      if (this.teacherValue == 1) {
        if (e == "-") {
          if (this.teacherObj.current <= 0) {
            this.teacherObj.current = 6;
          } else {
            this.teacherObj.current--;
          }
        } else if (e == "+") {
          if (this.teacherObj.current >= 6) {
            this.teacherObj.current = 0;
          } else {
            this.teacherObj.current++;
          }
        }
        this.getEvaluatorScoreRankingReport3();
      } else {
        if (e == "-") {
          if (this.patriarchObj.current <= 0) {
            this.patriarchObj.current = 6;
          } else {
            this.patriarchObj.current--;
          }
        } else if (e == "+") {
          if (this.patriarchObj.current >= 6) {
            this.patriarchObj.current = 0;
          } else {
            this.patriarchObj.current++;
          }
        }
        this.getEvaluatorScoreRankingReport2();
      }
    },
  },
};
</script>

<style scoped>
/deep/ .el-radio {
  margin-right: 10px !important;
  color: #a7b4cf !important;
}
/deep/ .el-radio__inner {
  width: 16px;
  height: 16px;
  background-color: #dbe0eb;
  border: 1px solid #e5e9f1;
}
/deep/ .el-radio__inner::after {
  width: 6px;
  height: 6px;
}
/deep/ .el-radio__label {
  font-size: 16px;
}
.block_css {
  width: 30px;
  height: 30px;
  border-radius: 2px;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  font-size: 18px;
  background-color: #52bee948;
  margin-right: 10px;
  flex-shrink: 0;
}
.teacher_box_css {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 17px;
}
.teacher_box_css > div {
  width: 47%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.teacher_box_css > div:nth-child(2n - 1) {
  margin-right: 5%;
}
.teacher_box_css > div:nth-child(1) > .block_css {
  background-color: #bf3a50 !important;
}
.teacher_box_css > div:nth-child(2) > .block_css {
  background-color: #be693b !important;
}
.teacher_box_css > div:nth-child(3) > .block_css {
  background-color: #427bf5 !important;
}
.screen_box_css {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
}
.screen_box_css_div {
  display: flex;
  align-items: center;
}
.lineHeight-css {
  line-height: 180px !important;
}
</style>
