<template>
  <div>
    <div class="ps_box-top-css">
      <div class="ps_box-top-css-div">{{titleList[current]}}</div>
      <div class="ps_box-top-css-after" @click="currentClick('-')"></div>
      <div class="ps_box-top-css-before" @click="currentClick('+')"></div>
    </div>
    <div class="ps_box-content-css ps_height350">
      <div class="screen_box_css">
        <div class="screen_box_item_css" v-for="(item, index) in showList" :key="index" :class="{ select_box_css: showValue == index }" @click="selectionRange(index, item)">
          {{ item }}
        </div>
        <el-date-picker @change="pickerChange()"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd" v-model="timeValue" type="daterange" align="right" unlink-panels class="pickerTime" ref="datePicker" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div v-if="showE">
        <div id="classRanking" class="classRanking-classRanking-css"></div>
      </div>
      <div v-else class="NoRankingYet">
        暂 无 排 名
      </div>
      <div class="ps_content-border-css">
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
let myChart = null;
import moment from 'moment';
import { ClassScoreRankingReport } from "@/api/index.js";
export default {
  data() {
    return {
      showValue: 0,
      showList: ["本学期", "本月", "本周", "自定义"],
      titleList:['全校班级榜','一年级班级榜','二年级班级榜','三年级班级榜','四年级班级榜','五年级班级榜','六年级班级榜'],
      current: 0,
      showE: true,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      timeValue: "",
      startDate: '',
      endDate: '',
      time: ''
    };
  },
  created() {
    this.time = localStorage.getItem('newTime') || moment().format('YYYY-MM-DD')
    this.getClassScoreRankingReport()
  },
  methods: {
    getClassScoreRankingReport(){
      this.showE = true
      ClassScoreRankingReport({
        top:10,
        Grade:this.current,
        startDate: this.startDate,
        endDate: this.endDate
      }).then(res =>{
        if(res.data.length == 0){
          this.showE = false
        } else {
          this.showE = true
          let valueArr = []
          let labelArr = []
          res.data.map(val =>{
            valueArr.push(Number(val.Score))
            labelArr.push(val.className)
          })
          
          // 图表问题这里需传倒序
          this.$nextTick(() =>{
            this.classRanking(labelArr.reverse(),valueArr.reverse());
          })
        }
      })
    },
    selectionRange(index, item) {
      if (item == "自定义") {
        this.$refs.datePicker.focus();
      } else {
        this.timeValue = ''
        this.showValue = index;
        if(index == 0){
          this.startDate = ''
          this.endDate = ''
        } else if(index == 1){
          this.startDate = moment().format('YYYY-MM') + '-01'
          this.endDate = moment().format('YYYY-MM-DD')
        } else if(index == 2){
          let date = moment();
          let dow = date.day();
          this.startDate = date.subtract(dow-1, 'days').format('YYYY-MM-DD')
          this.endDate = moment().format('YYYY-MM-DD')
        }
        this.getClassScoreRankingReport()
      }
    },
    // 选择日期
    pickerChange(){
      this.showValue = 3
      this.startDate = this.timeValue[0]
      this.endDate = this.timeValue[1]
      this.getClassScoreRankingReport()
    },
    // 切换年级
    currentClick(e) {
      if (e == "-") {
        if (this.current <= 0) {
          this.current = 6;
        } else {
          this.current--;
        }
      } else if (e == "+") {
        if (this.current >= 6) {
          this.current = 0;
        } else {
          this.current++;
        }
      }
      this.getClassScoreRankingReport()
    },
    async classRanking(labelArr,valueArr) {
      if (myChart) {
        myChart.dispose();
      }

      myChart = null;
      this.$forceUpdate();
      await new Promise((res) => setTimeout(res, 50));
      myChart = echarts.init(document.getElementById("classRanking"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: 28,
          bottom: 20,
          left: 80,
          right: 40,
        },
        xAxis: {
          type: "value",
          position: "top",
          axisTick: { show: false },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#082863",
            },
          },
          axisLabel: {
            color: "#fff",
            interval: 0,
            formatter: function(value) {
              if (Number.isInteger(value)) {
                return value;
              } else {
                return "";
              }
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#082863",
            },
          },
        },

        yAxis: {
          nameGap: 10,
          nameLocation: "end",
          nameTextStyle: {
            color: "#fff",
            align: "right",
          },
          type: "category",
          axisTick: { show: false },
          data: labelArr,
          axisLine: {
            lineStyle: {
              color: "#082863",
            },
          },
          axisLabel: {
            color: "#fff",
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 12,
            data: valueArr,
            itemStyle: {
              normal: {
                color: function(params) {
                  let colorArr = ["#FDAA01", "#FDAA01", "#FDAA01", "#34BDEF", "#34BDEF", "#34BDEF", "#46F5A8", "#46F5A8", "#46F5A8", "#46F5A8" ];
                  let colorList = (colorArr.slice(0, valueArr.length)).reverse()
                  return new echarts.graphic.LinearGradient(
                    0,
                    0,
                    1,
                    0,
                    [
                      { offset: 0, color: colorList[params.dataIndex] + "07" },
                      { offset: 1, color: colorList[params.dataIndex] },
                    ],
                    false
                  );
                },
              },
            },
            label: {
              show: true,
              position: "right",
              formatter: function(params) {
                const xData = params.data;
                return `{style|${xData}}`;
              },
              rich: {
                style: {
                  color: "#fff",
                },
              },
            },
          },
        ],
      });
    },
  },
};
</script>

<style scoped>
.classRanking-classRanking-css {
  width: 470px;
  height: 330px;
}
</style>
